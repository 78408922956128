import axios, { AxiosInstance } from 'axios'

let accessTokenRefreshingInProgress = false
let _accessToken
export const getToken = () => _accessToken
export const setToken = accessToken => (_accessToken = accessToken)

const configWithAuthorizationHeader = config => {
  if (_accessToken) config.headers.Authorization = `Bearer ${_accessToken}`
  return config
}

const retry = async config => {
  try {
    return await axios.request(configWithAuthorizationHeader(config))
  } catch (error2) {
    return Promise.reject(error2)
  }
}

const waitForTokenRefreshedAndRetry = config => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (!accessTokenRefreshingInProgress) {
        clearInterval(interval)
        retry(config).then(resolve).catch(reject)
      }
    }, 1000)
  })
}

export const refreshToken: () => Promise<string> = () => {
  accessTokenRefreshingInProgress = true
  return new Promise((resolve, reject) => {
    axios
      .post('/api/auth/refreshToken')
      .then(({ data }) => {
        setToken(data?.accessToken)
        accessTokenRefreshingInProgress = false
        resolve(data?.accessToken)
      })
      .catch(reason => {
        if (reason.response.status === 500) {
          window.open('/login', '_self')
        }
        accessTokenRefreshingInProgress = false
        reject(reason)
      })
  })
}

export const authAxios: () => AxiosInstance = () => {
  const _axios = axios.create()

  _axios.interceptors.request.use(
    config => configWithAuthorizationHeader(config),
    error => Promise.reject(error)
  )

  _axios.interceptors.response.use(
    c => c,
    async error => {
      if (error.response?.status === 401) {
        if (accessTokenRefreshingInProgress) {
          return await waitForTokenRefreshedAndRetry(error.config)
        } else {
          await refreshToken()
          return await retry(error.config)
        }
      } else {
        return Promise.reject(error)
      }
    }
  )
  return _axios
}
